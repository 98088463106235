@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Preahvihear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.site-title {
  font-family: 'Preahvihear', sans-serif;
  color: #08FF15;
}

.hero-title {
  font-family: 'Preahvihear', sans-serif;
  word-spacing: 0.5em;
  line-height: 4rem;
}

.hero-title-type {
  font-family: 'Preahvihear', sans-serif;
  word-spacing: 0.5em;
  line-height: 4rem;
  color: #08FF15;
}

.hero-exp {
  color: #D1D5DB;
  opacity: 1;
  font-family: 'Montserrat', sans-serif;
  line-height: 4rem;
  font-style: italic;
  font-weight: 600;
}

.hero-sub-title {
  font-family: 'Montserrat', sans-serif;
  color: #D1D5DB;
  opacity: 1;
  line-height: 2rem;
}

.hero-background {
  background-image: url('./resources/background_hero.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.about-me-title {
  font-family: 'Montserrat Medium', sans-serif;
  color: #162251;
  text-align: center;
}

.about-me-story {
  font-family: 'Montserrat', sans-serif;
  color: #162251;
  font-weight: 600;
}

.about-me-subtitle {
  font-family: 'Montserrat', sans-serif;
  color: #162251;
}

.project-count {
  font-family: 'Montserrat', sans-serif;
  color: #162251;
  font-weight: 600;
}

.project-count-title {
  font-family: 'Montserrat', sans-serif;
  color: #162251;
}


body {
  margin: 0;
}

h2 {
  @apply text-xl md:text-4xl;
}

p {
  @apply text-xl tracking-tight leading-relaxed;
}